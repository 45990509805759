.cart-info-container{
    padding: var(--triple-default-spacing);
}

.cart-info h2 {
    margin-bottom: var(--default-spacing);
}

.cart-info {
    padding: 15px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: var(--default-spacing);
}

.cart-items-list {
    border-top: 1px solid #ddd;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
    gap: var(--double-default-spacing); /* Space between items */
}

.cart-item {
    flex: 1 1 calc(33.333% - var(--double-default-spacing)); /* Each item takes up 1/3 of the row minus spacing */
    max-width: 100%;
    flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
    margin-bottom: var(--double-default-spacing); /* Ensure consistent spacing */
}

.cart-item h3 {
    margin-bottom: var(--default-spacing);
}

.item-details {
    display: flex;
    flex-wrap: wrap;
    gap: var(--default-spacing);
}

.image-container .product-image {
    width: 16rem;
    height: 16rem;
    object-fit: contain;
}

.info-container p {
    margin: 5px 0;
}
