.admin-header {
    max-width: 100%;
    max-height: 8rem;
    display: flex;
    justify-content: center;
    background-color: var(--background-color);
    color: var(--text-color-secondary);
    border-bottom: 8px solid var(--primary-color);
}
  
.admin-header__all{
    min-width: 80%;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;
}

  .admin-header__left h1 {
    font-size: 2rem;
    margin: 0;
  }
  
  .admin-header__right {
    display: flex;
    align-items: center;
  }
  
  .admin-header__right span {
    margin-right: 0.5rem;
    font-size: 1.5rem;
    color: var(--text-color-secondary);
  }
  