
.form-group-login {
  margin-bottom: 1.5rem;
}

.form-group-login label {
  display: block;
  margin-bottom: 1rem;
}

.form-group-login input {
  width: 24rem;
  padding: 0.75rem;
  border: 1px solid var(--primary-color);
  border-radius: var(--default-border-radius-extra);
  margin-left: -0.6rem;
}

.modal-login-side{
  display: flex;
  flex-direction: column;
}

.modal-login-side form{
  margin-top: 8%;
}

.form-group-login{
  min-width: 16rem;
  font-size: 20px;
}

.forgot-pass{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.forgot-pass label{
  cursor: pointer;
  color: var(--text-color-primary);
}

.submit-form{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-login-cadastro-side{
  width: 100%;
  margin-left: 16px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 2px solid rgba(196, 196, 196, 0.5);
}

.modal-login-cadastro-side-message{
  font-size: 24px;
  margin-bottom: var(--triple-default-spacing);
}

.modal-login-cadastro-side-button button{
  background-color: var(--button-color-primary);
  color: var(--text-color-primary);
  padding: 0.75rem;
  border: none;
  border-radius: var(--default-border-radius);
  cursor: pointer;
  font-size: 1rem;
}

.modal-login-cadastro-side-button button:hover {
  background-color: var(--button-color-primary-hover);
}