.model-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--default-spacing);
    padding: var(--double-default-spacing);
}

.image-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.image-item span{
    margin-top: var(--default-spacing);
}

.swiper-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.model-images-options{
    display: flex;
    gap:var(--default-spacing);
    margin-bottom: 8px;
    align-items: center;
}

.model-images-options svg{
    padding-top: 4px;
}

.model-images-list{
    display: flex;
    gap:var(--default-spacing)
}