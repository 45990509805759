/* Navbar styles */
.navbar {
  width: 100%;
  color: var(--text-color-primary-light);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar__options {
  width: 100%;
  padding: 1rem;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__top__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
}

.navbar__logo {
  margin-left: 2rem;
}

.navbar__logo img {
  max-height: 3.5rem;
  height: auto;
  width: auto;
}

.navbar__menu {
  margin-right: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.navbar__menu-item a, .navbar__menu a{
  color: var(--text-color-primary);
  text-decoration: none;
}

.navbar__menu-item {
  display: flex;
  align-items: center;
  color: var(--text-color-primary);
  text-decoration: none;
  cursor: pointer;
}

.navbar__menu-item span {
  font-family: 'Roboto', sans-serif;;
  font-size: 16px;
  text-decoration: none;
  text-align: left !important;
}

.navbar__options__bottom {
  width: 100%;
  background-color: var(--secundary-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: var(--default-spacing);
}

.navbar__bottom {
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
}

.navbar__links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.ul-navbar-itens{
  display: flex;
}

.ul-navbar-itens li {
  list-style: none;
}

.navbar__link {
  color: var(--text-color-primary);
  font-size: 16px;
  text-decoration: none;
  position: relative;
  padding: 0.5rem 1rem;
}

.navbar__link a{
  text-decoration: none;
  color: var(--text-color-primary);
}

/* First-level submenu: dropdown */
.navbar__submenu {
  position: absolute;
  top: 100%; /* Drop down from the parent item */
  left: 0; /* Align with the parent item */
  background-color: var(--block-color);
  box-shadow: 0 4px 8px var(--shadow-color-primary);
  border-radius: var(--default-border-radius);
  display: none; /* Hide submenu by default */
  padding: var(--default-spacing);
  z-index: 10;
  min-width: 200px;
  white-space: nowrap; /* Prevents text from wrapping */
  flex-direction: column;
}

.navbar__submenu .navbar__link, .navbar__submenu .navbar__link .navbar__submenu .navbar__link, .navbar__submenu .navbar__link :hover, .navbar__submenu .navbar__link .navbar__submenu .navbar__link :hover{
  color: var(--text-color-secondary) !important;
}

/* Second-level and deeper submenus: side */
.navbar__submenu .navbar__submenu {
  position: absolute;
  top: 0; /* Aligns with the parent submenu item */
  left: 100%; /* Positions to the right of the parent submenu */
  background-color: var(--block-color);
  box-shadow: 0 4px 8px var(--shadow-color-primary);
  border-radius: var(--default-border-radius);
  display: none; /* Hide nested submenu by default */
  padding: var(--default-spacing);
  z-index: 10;
  min-width: 200px;
  white-space: nowrap;
}

/* Show submenu on hover */
.navbar__link:hover > .navbar__submenu {
  display: flex; /* Show submenu when hovering over the parent link */
}


.navbar__submenu li {
  list-style: none;
  padding: var(--default-spacing) var(--double-default-spacing);
  color: var(--text-color-secondary);
  cursor: pointer;
  text-align: left;
}

.navbar__submenu li:hover {
  color: var(--text-color-primary);
}

.navbar__submenu a{
  text-decoration: none;
  color: var(--text-color-secondary);
}

.navbar__combobox {
  display: flex;
  align-items: center;
  width: 16rem;
}

.navbar__select {
  padding: 8px 12px;
  border: 1px solid var(--primary-color);
  border-radius: var(--default-border-radius-extra);
  background-color: #ffffff;
  color: var(--text-color-secondary);
  min-width: 16rem;
  appearance: none;
}

.navbar__search-icon {
  margin-left: -30px;
  color: var(--text-color-primary);
  pointer-events: none;
}

.navbar__menu-item {
  position: relative; /* Posiciona o dropdown relativo ao contêiner pai */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar__dropdown {
  position: absolute;
  top: 100%; /* Posiciona o dropdown logo abaixo do nome/ícone */
  left: 0; /* Alinha o dropdown com o lado esquerdo do contêiner */
  background-color: var(--block-color);
  box-shadow: 0 4px 8px var(--shadow-color-primary);
  border-radius: var(--default-border-radius);
  display: none; /* Esconde o dropdown por padrão */
  flex-direction: column;
  padding: var(--default-spacing);
  z-index: 10;
  min-width: 200px;
  white-space: nowrap;
  margin-top: 0.5rem; /* Espaçamento entre o ícone e o dropdown */
}

.navbar__dropdown a, .navbar__dropdown p{
  text-decoration: none;
  color: var(--text-color-secondary);
}

/* Exibe o dropdown quando o estado isDropdownOpen é true */
.navbar__menu-item.active .navbar__dropdown {
  display: flex;
}

.navbar__dropdown-item {
  padding: var(--default-spacing);
  color: var(--text-color-secondary);
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: left;
}

.navbar__dropdown-item:hover {
  background-color: var(--hover-background-color);
}

.navbar__combobox {
  position: relative; /* Needed to position the dropdown */
}

.item-intern-cart{
  margin: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color-secondary);
}

.navbar__dropdown-cart-itens h3{
  color: var(--text-color-secondary);
  margin-bottom: 1rem;
}

.close-cart-navbar{
  margin-top: 1rem;
  width: 100%;
}

.navbar__select-container {
  position: absolute;
  top: 100%; /* Position below the input */
  left: 0;
  width: 100%; /* Make the dropdown as wide as the input */
  background-color: #fff; /* White background for the dropdown */
  border: 1px solid var(--primary-color); /* Match the border with your theme */
  border-radius: var(--default-border-radius-extra); /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  z-index: 999; /* Ensure the dropdown appears above other elements */
  display: none; /* Hide by default */
}

.navbar__select-container.show {
  display: block; /* Show when there are search results */
}

.custom-dropdown {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  padding: 0.5rem;
}

.custom-dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.custom-dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  color: var(--text-color-secondary);
  cursor: pointer;
}

.custom-dropdown-item:hover {
  background-color: #f0f0f0;
}

.product-image {
  width: 24px; /* Adjust image size as needed */
  height: 24px;
  margin-right: 0.5rem;
  object-fit: cover;
}

.product-link{
  color: var(--primary-color);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar__mobile {
    width: 100%;
    background-color: var(--primary-color);
    color: var(--text-color-primary-light);
    padding: 1rem;
  }

  .navbar__menu-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    color: var(--text-color-primary);
    padding: 0.5rem 1rem;
  }

  .navbar__top__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar__hamburger {
    font-size: 2rem;
    background: none;
    border: none;
    color: var(--text-color-primary-light);
    cursor: pointer;
  }

  .navbar__mobile__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-color);
    padding: 1rem;
  }

  .ul-navbar-itens {
    flex-direction: column;
    width: 100%;
  }

  .navbar__combobox {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  /* Second-level and deeper submenus: side */
.navbar__submenu .navbar__submenu {
  top: 100% !important;  /* Aligns with the parent submenu item */
  left: 0 !important; /* Positions to the right of the parent submenu */
}
}
