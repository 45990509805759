/* src/components/Pagination/Pagination.css */

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.pagination button {
  padding: 0.4rem 0.6rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.pagination .active {
  background-color: #333333;
  color: #ffffff;
}

.pagination .ellipsis {
  padding: 0.4rem 0.6rem;
  color: #666666;
}

.page-button {
  color: inherit;
  background-color: inherit;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
