.modal-edit-user{
    margin: var(--triple-default-spacing);
    display: flex;
    flex-direction: column;
    max-width: 32rem;
}

.modal-edit-user input{
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--primary-color);
    border-radius: var(--default-border-radius);
}

.modal-container-edit{
    background-color: var(--modal-primary-color);
    color: var(--text-color-primary);
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 2rem;
    border-radius: var(--default-border-radius-extra);
}