.dashboard-metrics{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--default-spacing);
    padding: var(--double-default-spacing);
    margin-bottom: var(--double-default-spacing);
  }

.dashboard-metrics h2{
  margin-bottom: var(--double-default-spacing);
}

.dashboard-first-info{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--triple-default-spacing);
}

.dashboard-first-info p{
  width: 30%;
}

.dashboard-chats{
  display: flex;
  flex-direction: column;
}

.dashboard-legend-recents{
  padding: var(--default-spacing);
  background-color: var(--primary-color);
}

.dashboard-legend-old{
  padding: var(--default-spacing);
  background-color: var(--button-color-primary-hover);
}

.dashboard-charts-info{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6rem; 
}