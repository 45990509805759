.modal-container-password {
    background-color: var(--modal-primary-color);
    color: var(--text-color-primary);
    display: flex;
    width: 32rem;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    padding: 2rem;
    border-radius: var(--default-border-radius-extra);
}

.modal-container-password h2, sub{
    margin-bottom: var(--triple-default-spacing);
}

.recovery-password-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: var(--double-default-spacing);
}