.loading-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensures it's always on top */
  }
  
  .loading-modal {
    background-color: white;
    padding: 2rem;
    border-radius: var(--default-border-radius-extra);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid var(--primary-color); /* Use your primary color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  