/* Container for image previews */
.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

/* Individual image preview */
.image-preview {
    position: relative;
    width: 12rem;
    height: 12rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Image styling */
.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Delete button for each image */
.image-preview button {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: rgba(255, 0, 0, 0.7);
    border: none;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    padding: 2px 6px;
    transition: background-color 0.2s;
}

/* Hover effect on delete button */
.image-preview button:hover {
    background-color: rgba(255, 0, 0, 0.9);
}

.add-image-option{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--default-spacing);
    width: 100%;
}