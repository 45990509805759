.admin-layout {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.admin-content {
  flex: 0 0 240px; /* Fixed width for the sidebar */
}

.admin-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.admin-main > * {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.admin-main > .admin-header {
  flex-shrink: 0; /* Prevent header from shrinking */
}

.admin-main > main {
  flex-grow: 1; /* Take up the remaining space */
  padding: 24px;
}

.admin-top{
  max-height: 8rem;
}

.href-history {
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.href-history span{
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.href-history span a {
  text-decoration: none; 
  color: black;
  cursor: pointer;
}

.link-history{
  color: var(--button-color-secundary) !important;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-table th, .admin-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.admin-table th {
  font-weight: bold;
}

.admin-table td {
  color: #333;
}

.admin-table .action-icons {
  display: flex;
  gap: 0.5rem;
}

.admin-button {
  display: block;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color: var(--button-color-primary); 
  color: var(--text-color-primary);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-button:hover {
  background-color: var(--button-color-primary-hover); 
}

.container-admin-page {
  margin: 1.5rem;
  padding: 1.5rem;
}

.container-admin-page h1, container-admin-page-title h1 {
  font-size: 2.5rem;
  font-family: 'Roboto', sans-serif;;
  margin-bottom: 1rem;
}

.container-admin-page-title{
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--double-default-spacing);
}

.container-admin-page-border{
  margin: 1.5rem;
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
}

.container-admin-page-filters, .container-admin-page-table{
  padding: 1.5rem;

}

.container-admin-page-table{
  margin-top: var(--double-default-spacing);
}

.sidebar-toggle-btn {
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 10px 15px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  z-index: 1000; /* Ensure it appears above other content */
}

.admin-options-top {
  display: none; /* Hide by default */
}

/* Display toggle button on mobile */
@media (max-width: 768px) {
  .sidebar-toggle-btn {
    display: block;
  }
  
  .admin-options-top {
    display: block; /* Show only on screens 768px wide or smaller */
    position: absolute;
    top: 10px; /* Adjust position as needed */
    left: 10px;
    z-index: 1000; /* Ensure it appears above other elements */
  }

  .admin-content {
    display: none; /* Hide sidebar by default on mobile */
  }

  .admin-content.visible {
    display: block; /* Show sidebar when visible */
  }

  /* Make main content full width on mobile */
  .admin-main {
    margin-left: 0;
    width: 100%;
  }
}