/* Container for the entire section */
.model-list-section {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping */
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: center; /* Center the content when wrapping */
}

.model-filters {
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 300px;
    position: relative;
    margin-left: 1rem;
}

.filter-section {
    margin-top: 2rem;
}

.filter-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.filter-option {
    display: flex;
    align-items: center;
    gap: 8px;
}

.filter-option input[type="checkbox"] {
    accent-color: #4c2b5a; 
}

.filter-option label {
    font-size: 14px;
    cursor: pointer;
}

.selected {
    font-weight: bold;
    color: #4c2b5a; /* Selected item color */
}


/* Model items container */
.model-items {
    flex: 3 1 600px; /* Allow the model items section to shrink and grow */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}

/* Grid for the model items */
.model-items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1020px; /* Center the items by limiting the width */
}

@media (min-width: 768px) {
    .model-items-grid {
        grid-template-columns: repeat(3, 1fr); /* Max 3 items per line */
    }

    .filter-options{
        flex-direction: column;
    }
}

/* Individual model item card */
.model-item-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    max-width: 100%; /* Make sure item width is responsive */
}

.model-item-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

/* Model item image */
.model-item-image {
    width: 300px;
    max-height: 300px;
    height: auto;
    display: block;
    margin: 0 auto; /* Center the image within the card */
}

/* Model item details */
.model-item-details {
    padding: 15px;
}

.model-item-details h5 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center; /* Center item titles */
}

.model-item-details h6 {
    margin-bottom: 5px;
    text-align: center; /* Center item titles */
}

.model-item-details p {
    font-size: 14px;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: left; /* Center description text */
}

/* Total models subtext */
.model-items sub {
    font-size: 14px;
    color: #888;
    text-align: center;
    margin-top: 10px;
}

/* Pagination styling */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
}

.model-filters-input{
    display: flex;
    align-items: center;
}

.filter-section-title{
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .model-filters {
        background-color: #f9f9f9;
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
        width: 100%;
        max-width: 10000px;
        position: relative;
        margin-left: 0;
    }

    .model-item-image {
        width: 150px;
        max-height: 200px;
    }
}