:root {
  /* Cor do background */
  --background-color: #F6F6F6;
  --admin-button-color: #c9c7c7;

  /* Cor do header, footer e outros elementos principais */
  --primary-color: #1E293B; /* HEX 009ad6 */
  --modal-primary-color: #1E293BE5;

  /* Cor dos painéis */
  --secundary-color: #222F43; /* Same as primary for now */
  --secundary-color-hover: #383838;

  /* Cores do botão principal */
  --button-color-primary: #334981;
  --button-color-primary-hover: #4c6299; /* Keeping this as is */
  --button-color-primary-disabled: #cccccc; /* Keeping this as is */
  --button-color-primary-wrong: #B00020; /* Keeping this as is */
  --button-color-primary-wrong-hover: #CF6679; /* Keeping this as is */

  --button-color-secundary: #009AD6;
  --button-color-secundary-hover: #34b1e2; /* Keeping this as is */
  --button-color-secundary-wrong: #B00020; /* Keeping this as is */
  --button-color-secundary-wrong-hover: #CF6679; /* Keeping this as is */

  --second-section-color: #E6E6E6;

  --button-color-primary-right: rgb(56, 142, 60);
  --button-color-primary-right-hover: rgba(56, 142, 60, 0.9);

  /* Cor do texto nos painéis */
  --text-color-primary: #eeeeee; /* Darker color for readability */
  --text-color-primary-light: #fefeff;
  --text-color-primary-dark: #cccccc;
  --text-color-secondary: #333333; /* Slightly lighter for secondary text */
  --text-color-accent: #007ea7; /* A nice blue accent that contrasts */
  --text-color-code: #2a4d69; /* Darker blue for code blocks */
  --text-color-button: #ffffff; /* White for buttons */

  --button-color-secondary: rgb(37, 26, 166); /* Keeping this */
  --separator-color-primary: rgba(173, 179, 187, 0.48);
  --shadow-color-primary: rgba(0, 0, 0, .12);
  --shadow-color-secondary: rgba(0, 0, 0, .12);
  --separator-marker-color-primary: #006B59;

  --block-color: #FAFDFA;
  --block-color-secundary: #FAFDFA;

  --color-surface: #FAFDFA;
  --color-surface-variant: #1F1F1F;

  --loader-color: #009ad6; /* HEX 009ad6 */
  --panel-border-color: #eaebeb;

  --modal-background-color: rgba(255, 255, 255, 0.25);

  --footer-heigth: 48px;

  /* Espaçamentos padrões */
  --default-spacing: 8px;
  --double-default-spacing: calc(2 * var(--default-spacing));
  --triple-default-spacing: calc(3 * var(--default-spacing));

  --default-max-width: 1080px;

  --default-border-radius: 4px;
  --default-border-radius-extra: 16px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color); /* HEX 151e35 */
  color: var(--text-color-secondary);
  width: 100%;
  height: 100%;
  text-align: justify;
}

.Toastify__progress-bar {
  background-color: var(--primary-color) !important;
}

.Toastify__toast-icon svg {
  fill: var(--primary-color) !important; /* Adjust the icon color */
}

.modal-title{
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-deffault-bottom{
  margin-bottom: var(--default-spacing);
}

.space-double-bottom{
  margin-bottom: var(--double-default-spacing);
}

.space-triple-bottom{
  margin-bottom: var(--triple-default-spacing);
}

.main-button-not-click, .main-button{
  padding: 0.75rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.main-button {
  background-color: var(--button-color-primary);
  color: var(--text-color-button);
  border-radius: var(--default-border-radius);
}

.main-button:hover {
  background-color: var(--button-color-primary-hover);
}

.main-button:disabled {
  background-color: var(--button-color-primary-disabled);
  cursor: not-allowed;
}

.submit-form button{
  margin-top: 8px;
  padding: 16px;
  width: 8rem;
}

.form-group-input{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-group-input-icon{
  margin-left: -32px;
}

.text-align-center{
  text-align: center !important; 
}

.clickable {
  cursor: pointer;
}

.option-link {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

.option-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: width 0.5s ease-in-out; 
}

.option-link:hover::after {
  width: 100%;
}

.brighten-on-hover:hover {
  filter: brightness(1.2); /* Increase brightness by 20% */
}

.padding-top-4-px{
  padding-top: 4px;
}

.padding-bottom-4-px{
  padding-bottom: 4px;
}

.padding-top-default{
  padding-top: var(--default-spacing);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: var(--modal-primary-color);
  padding: 2rem;
  border-radius: var(--default-border-radius-extra);
  width: 100%;
  max-width: 852px;
  height: 485px;
  color: var(--text-color-primary);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-size: 2.5rem;
  background: none;
  border: none;
  color: var(--text-color-primary);
  cursor: pointer;
}

.modal-content{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-component {
  display: flex;
  justify-content: flex-end;
  width: 100%; 
}

.space-component{
  display: flex;
  justify-content: space-between; /* Aligns the button to the right */
  width: 100%; 
}

.main-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--primary-color);
  border-radius: var(--default-border-radius);
}

.margin-top-default{
  margin-top: var(--default-spacing);
}

.div-with-border{
  border: 1px solid #e0e0e0;
}

.padding-default{
  padding: 0.75rem;
}

.margin-top-default{
  margin-top: var(--double-default-spacing);
}

.item-active{
  background-color: var(--button-color-primary-right);
  color: var(--text-color-primary) !important;
  padding: var(--default-spacing);
}

.item-inactive{
  background-color: var(--button-color-primary-wrong);
  color: var(--text-color-primary) !important;
  padding: var(--default-spacing);
}

.item-in-progress{
  background-color: var(--button-color-primary-right);
  color: var(--text-color-primary) !important;
  padding: var(--default-spacing);
}

.item-open{
  background-color: var(--button-color-primary);
  color: var(--text-color-primary) !important;
  padding: var(--default-spacing); 
}

.div-flex-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gap-default{
  gap: var(--default-spacing);
}

.second-color{
  color: var(--text-color-secondary) !important;
}

.second-color :hover{
  color: var(--text-color-secondary) !important;
}

.column-4px{
  content: '';
  display: block;
  width: 4px;
  max-width: 4px;
  height: 4rem;
  background-color: var(--primary-color);
  margin: 0.5rem;
}

.column-2px{
  content: '';
  display: block;
  width: 2px;
  max-width: 2px;
  height: 4rem;
  background-color: var(--background-color);
  margin: 0.5rem;
}

textarea{
  padding: 0.5rem;
  font-size: 0.9rem;
}

.shadow-on-hover:hover {
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5); /* Shadow only at the bottom */
  transition: box-shadow 0.3s ease;
}

.modal-container-generic{
  background-color: var(--modal-primary-color);
  padding: 2rem;
  border-radius: var(--default-border-radius-extra);
  color: var(--text-color-primary);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.modal-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}
  
.modal-message {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}
  
.modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
    gap: 1rem;
}
  
.modal-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}