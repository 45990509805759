.add-option-by-side{
    display: flex;
    align-items: center;
    gap: var(--default-spacing);
}

.add-option-by-side-icon{
    margin-bottom: 12px;    
}

.brand-select{
    margin-top: -6px;
}