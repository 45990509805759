.cart-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 880px;
    margin: auto;
    flex-wrap: wrap;
    background-color: var(--background-color);
    border-radius: var(--default-border-radius-extra);
}

.cart-items-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 1rem;
    margin-bottom: 1rem;
    color: var(--text-color-secondary);
}

.cart-items-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--separator-color-primary);
    padding: 1rem;
}

.item-image{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.item-image img {
    width: 60px;
    height: 60px;
    border-radius: var(--default-border-radius);
    object-fit: cover;
}

.no-image-placeholder {
    width: 60px;
    height: 60px;
    background-color: var(--block-color);
    border-radius: var(--default-border-radius);
}

.item-details {
    padding-left: 1rem;
}

.item-name {
    font-weight: bold;
    text-align: left;
    color: var(--text-color-secondary);
}

.item-quantity {
    display: flex;
    align-items: center;
    gap: 0.1rem;
}

.button-options-itens {
    background: none;
    border: 1px solid var(--separator-color-primary);
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border-radius: var(--default-border-radius);
    transition: background-color 0.2s;
}

.item-quantity input {
    width: 40px;
    text-align: center;
    border: none;
    background-color: transparent;
    font-size: 1rem;
}

.remove-item-btn {
    background: none;
    border: none;
    color: var(--button-color-primary-wrong);
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.2s;
}

.remove-item-btn:hover {
    color: var(--button-color-primary-wrong-hover);
}

.cart-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}
