.clients-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.search-bar {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 20px;
  flex-grow: 1;
}

.filter-btn, .new-client-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.filter-btn, .new-client-btn {
  background-color: var(--admin-button-color);
}

.new-client-btn {
  font-weight: bold;
}

.edit-btn, .delete-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.clients-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.clients-header-search{
  display: flex;
  align-items: center;
  width: 50%;
}