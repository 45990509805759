.configurations-list-options{
    margin-top: var(--triple-default-spacing);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.configuration-options-column{
    margin: var(--triple-default-spacing);
    display: flex;
    flex-direction: row;
}

.configuration-option{
    padding: var(--double-default-spacing);
    background-color: var(--button-color-primary);
    color: var(--text-color-primary);
}