.footer {
  background-color: var(--primary-color);
  color: var(--text-color-primary-light);
  padding: var(--double-default-spacing) 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--default-max-width);
  width: 100%;
  gap: 4rem;
}

.footer__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  color: var(--text-color-primary-light);
}

.footer__logo {
  font-size: 2rem;
  color: var(--text-color-primary-light);
}

.footer__social {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
}

.footer__icon {
  color: var(--text-color-primary-light);
  cursor: pointer;
  transition: color 0.3s ease;
  display: flex;
  flex-direction: row;
}

.footer__icon:hover {
  color: var(--button-color-secundary-hover);
}

.footer__links, .footer__social {
  flex: 1;
  text-align: center;
}

.footer__social {
  flex: 1;
  text-align: left;
  margin-left: 1.4rem;
}

.footer__links p {
  margin: 0;
}

.footer__links p:not(:last-child) {
  margin-bottom: var(--default-spacing);
}

.footer__separator {
  border-left: 1px solid var(--text-color-primary-light);
  height: 100%;
  margin: 0 var(--default-spacing);
}

.footer__logo img {
  max-height: 3.5rem;
  height: auto;
  width: auto;
}

.footer__icon img {
  max-height: 2rem;
  height: auto;
  width: auto;
}

.footer__icon p {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.footer__social p{
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .footer__content {
    display: flex;
    flex-direction: column;
  } 
}