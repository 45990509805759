.shopping-cart-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 880px;
    margin: auto;
    flex-wrap: wrap;
    background-color: var(--background-color);
    border-radius: var(--default-border-radius-extra);
}

.shopping-cart-page-container sub{
    margin-top: var(--triple-default-spacing);
}

.shopping-cart-item{
    margin-bottom: var(--triple-default-spacing);
}

.shopping-cart-items-header, .item-shopping-cart-list{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--separator-color-primary);
    padding: 1rem;
}