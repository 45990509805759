.mail-details-page {
    padding: 20px;
}
  
.mail-info, .person-info {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}
  
.mail-info p, .person-info p {
    margin: 4px 0;
}
  
.mail-actions {
    display: flex;
    gap: 10px;
}