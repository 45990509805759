.password-field {
    margin-bottom: 1rem;
    max-width: 256px;
}
  
.password-field label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}
  
.input-container{
    display: flex;
    align-items: center;
}

.input-container input {
    width: 16rem;
    display: flex;
    align-items: center;
    border-radius: var(--default-border-radius);
    padding: 0.5rem;
}
  
.icon-container {
    cursor: pointer;
}

.button-field-opton{
    display: flex;
    justify-content: right;
    max-width: 256px;
}