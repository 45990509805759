.model-description{
    margin-bottom: 16rem;
}

.model-description-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    margin-top: 3rem;
    margin-left: 10%;
    margin-right: 10%;
}

.model-details-image {
    flex: 1;
    max-width: 400px;
}

.model-details-image img {
    width: 100%;
    height: auto;
    border-radius: var(--default-border-radius);
}

.model-details {
    flex: 2;
    min-width: 300px;
    padding: 1rem;
}

.model-details h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
}

.model-details h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 2px solid var(--separator-color-primary);
    display: inline-block;
    color: var(--text-color-secondary);
}

.model-details p {
    font-size: 1rem;
    line-height: 1.5;
    white-space: pre-line;
    color: var(--text-color-secondary);
}

.model-measures-container {
    margin: 2rem auto;
    max-width: 440px;
    padding: 0 1rem; 
    text-align: center; 
}

.model-measures-container-title{
    width: 100%;
    justify-content: space-evenly;
    text-align: left; 
    margin-bottom: 1rem;
}

.model-measures-container h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    display: inline-block; /* Ensure the header is displayed as a block element */
    border-bottom: 2px solid var(--separator-color-primary);
    color: var(--text-color-secondary);
}

.measure-item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--separator-color-primary);
}

.measure-item span {
    font-size: 1rem;
    color: var(--text-color-secondary);
}

.measure-description{
    display: flex;
    flex-direction: column;
    justify-content: left;
    flex-wrap: wrap;
    align-items: center;
    text-align: justify;
}

.measure-description p{
    width: 100%;
    margin: 1rem;
}

.model-details-button{
    width: 100%;
}

.measure-add-button {
    background-color: var(--button-color-primary);
    color: var(--text-color-button);
    border: none;
    border-radius: var(--default-border-radius);
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 2rem;
}


.measure-add-button:hover {
    background-color: var(--button-color-primary-hover);
}

.measure-add-button-remove {
    border: none;
    border-radius: var(--default-border-radius);
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 2rem;
}

@media (max-width: 768px){
    .measure-add-button {
        background-color: var(--button-color-primary);
        color: var(--text-color-button);
        border: none;
        border-radius: var(--default-border-radius);
        padding: 0.5rem 1rem;
        cursor: pointer;
        transition: background-color 0.3s;
        margin: 1rem;
    }   

    .measure-add-button-remove {
        border: none;
        border-radius: var(--default-border-radius);
        padding: 0.5rem 1rem;
        cursor: pointer;
        transition: background-color 0.3s;
        margin: 1rem;
    }   
}

.client-table {
    width: 100%;
    border-collapse: collapse;
}
  
.client-table th, .client-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
  
.client-table th {
    font-weight: bold;
}
  
.client-table td {
    color: #333;
}
  
.client-table .action-icons {
    display: flex;
    gap: 0.5rem;
}