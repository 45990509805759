.home-client-page {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0;
}
  
.home-section {
    color: var(--text-color-primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.section-1 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: var(--text-color-light);
    height: 200px; /* Adjust height as necessary */
    position: relative;
}
  
.section-1-title {
    font-size: 2rem; /* Adjust font size as necessary */
    line-height: 1.2;
    font-weight: bold;
    margin: 0;
    position: absolute;
    font-family: 'Roboto', sans-serif;;
    bottom: 20px; /* Distance from the bottom */
    left: 20px;   /* Distance from the left */
    color: var(--text-color-secondary); /* Ensure text color contrasts with the background */
    padding-left: 8%;
}

  
.overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for text contrast */
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 8px;
}
  
.items-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
    gap: 2rem;
    justify-content: center;
    margin-top: var(--triple-default-spacing);
}
  
.item-section-2 {
    max-width: 100px;
    text-align: center;
}
  
.item-section-2-img {
    width: auto;
    height: auto;
    border-radius: 8px;
}
  
.item-section-2-description {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color-secondary);
}
  
.section-2 {
    text-align: left;
    padding: 1rem 0.5rem;
    background-color: var(--background-color); /* Add a background color if needed */
}

.section-2-title {
    display: flex;
    width: 100%;
    padding-left: 8%;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--text-color-secondary);
    font-family: 'Roboto', sans-serif;;
}
  
.items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8rem;
    justify-content: center;
}
  
.item-section-2 {
    max-width: 56rem;
    text-align: center;
    justify-content: center;
    transition: transform 0.2s;
}

.item-section-2 p{
    color: var(--text-color-secondary);
}

.item-section-2:hover {
    transform: translateY(-10px);
}
  
.item-section-2-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
  
.item-section-2-description {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color-secondary);
    font-weight: bold;
}
  
/* Section 3 Styles */
.section-3 {
    background-color: var(--second-section-color); /* Use a light blue background as shown */
    padding: 3rem 1rem;
    text-align: center;
}
  
.section-3-title {
    font-size: 2rem;
    color: var(--text-color-secondary);
    font-family: 'Roboto', sans-serif;;
    margin-bottom: 1.5rem;
    position: relative;
}
  
.section-3-title::after {
    content: '';
    display: block;
    width: 16rem;
    height: 4px;
    background-color: var(--primary-color);
    margin: 0.5rem auto;
}
  
.items-container-section-3 {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
}
  
.item-section-3 {
    max-width: 300px;
    position: relative;
    text-align: center;
    transition: transform 0.3s;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: white;
}
  
.item-section-3:hover {
    transform: translateY(-8px);
}
  
.item-section-3-img {
    width: 100%;
    height: auto;
    border-radius: 12px 12px 0 0;
}
  
.item-section-3-description {
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color-secondary) !important;
    padding: 0.5rem;
    background-color: var(--text-color-primary);
    color: white;
    border-radius: 0 0 12px 12px;
}
  
/* Section 4 Styles */
.section-4 {
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    background-color: var(--background-color); /* Adjust as needed */
}

.section-4-title {
    font-size: 2rem;
    color: var(--text-color-secondary);
    font-family: 'Roboto', sans-serif;;
    margin-bottom: 1.5rem;
    position: relative;
}

.section-4-title::after {
    content: '';
    display: block;
    width: 16rem;
    height: 4px;
    background-color: var(--primary-color);
    margin: 0.5rem auto;
}

.items-container-section-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    max-width: 1520px;
    margin: 0 auto; /* Center the container */
}

.items-container-section-4 {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    max-width: 1020px; /* 3 items at 300px each + 2 gaps of 2rem (32px) */
    margin: 0 auto; /* Center the container */
}

.item-section-4 {
    width: 300px; /* Fixed width of 300px */
    text-align: center;
    transition: transform 0.3s;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.item-section-4:hover {
    transform: translateY(-8px);
}

.item-section-4-img {
    width: 100%;
    height: auto;
    border-radius: 12px 12px 0 0;
}

.item-section-4-description {
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color-secondary) !important;
    padding: 0.5rem;
    height: 100%;
    /* background-color: var(--text-color-primary); */
    color: white;
    border-radius: 0 0 12px 12px;
}

.button-section-4{
    margin-top: var(--triple-default-spacing);
}

/* Section 5 Styles */
.section-5 {
    background-color: var(--second-section-color); /* Light blue background */
    padding: 3rem 1rem;
    text-align: center;
}

.section-5-title {
    font-size: 2rem;
    color: var(--text-color-secondary);
    font-family: 'Roboto', sans-serif;;
    margin-bottom: 1.5rem;
    position: relative;
}

.section-5-title::after {
    content: '';
    display: block;
    width: 16rem;
    height: 4px;
    background-color: var(--primary-color);
    margin: 0.5rem auto;
}

.items-container-section-5 {
    display: flex;
    gap: 3rem;
    justify-content: center;
    flex-wrap: wrap;
    align-items: stretch; /* Ensures all items have the same height */
}

.item-section-5 {
    width: 300px; /* Fixed width */
    text-align: left;
    transition: transform 0.3s;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out title and description */
}

.item-section-5:hover {
    transform: translateY(-8px);
}

.item-section-5-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.item-section-5-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color-primary);
    margin-bottom: 0.5rem;
}

.item-section-5-item-title {
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color-secondary);
    line-height: 1.5;
}

.item-section-5-description {
    font-size: 1rem;
    color: var(--text-color-secondary);
    line-height: 1.5;
    flex-grow: 1; /* Makes the description take available space */
}

.home-client-page > .home-section.section-1{
    height: 400px;
}