.profile-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
  
.box {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
}

.box-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px
}

.info-group {
    margin-top: 16px;
}
  
.info-group p {
    margin: 8px 0;
}

.bigger-space{
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 10%;
    margin-right: 10%;
}
  