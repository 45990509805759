.modal-backdrop-register {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto; /* Enable scrolling */
    padding: 1rem; /* Add some padding for better spacing */
  }
  
  .modal-container-register {
    background-color: var(--modal-primary-color);
    padding: 2rem;
    border-radius: var(--default-border-radius-extra);
    width: 100%;
    max-width: 832px;
    max-height: calc(100vh - 4rem); /* Prevent overflow by setting a max height relative to the viewport */
    overflow-y: auto; /* Enable scrolling if the content overflows */
    color: var(--text-color-primary);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }  

.form-group-register label {
    display: block;
    margin-bottom: 1rem;
  }
  
.form-group-register input, select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--primary-color);
    border-radius: var(--default-border-radius);
}

.section-register{
    width: 100%;
    display: flex;
    align-items: center;
}

.section-register-option{
    cursor: pointer;
}

.section-register-selected{
    width: 50%;
    display: flex;
    justify-content: center;
    border-bottom: 8px solid var(--secundary-color-hover);
}

.section-register-no-selected{
    width: 50%;
    display: flex;
    justify-content: center;
}

.form-group-inside{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: var(--double-default-spacing);
}

.form-group-inside-name{
    width: 60%;
}

.form-group-inside-document{
    width: 40%;
}

.form-group-register{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.option-next-register{
    margin-top: var(--triple-default-spacing);
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.option-next-register button{
    width: 40%;
}

@media (max-width: 768px) {
    .modal-container-register {
        padding: 0.5rem;
    }  
}